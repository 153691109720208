import { stateStyles } from "./config.js";
import { createAdjacencyLists } from "./util";

const direction = { top: 0, right: 1, bottom: 2, left: 3 };

const utils = {
  removeEdge(evt) {
    const graph = this.graph;
    const eid = graph.get("lineEdgeId");
    if (eid) {
      graph.removeItem(eid);
      graph.set("lineSourceId", undefined);
      graph.set("lineEdgeId", undefined);
    }
  },
  // editItemClick(evt) {
  //   const item = evt.item;
  //   const graph = this.graph;
  //   const app = graph.get("elsFlowChart");
  //   app.apiSetCurrentItem(item, true);
  // },
  itemClickSelect(evt) {
    const item = evt.item;
    const id = item && item.getModel().id;
    const graph = this.graph;
    const cid = graph.get("currentSelectId");
    if (cid && cid == id) return;
    if (cid && graph.findById(cid)) {
      id?graph.setItemState(cid, "select", false):graph.clearItemStates(cid,['select','hover']);
      graph.set("currentSelectId", undefined);
    }
    if(!id) return;
    item.hasState('hover') && item.setState('hover',false)
    graph.setItemState(id, "select", true);
    graph.set("currentSelectId", id);
  },
  readdItem(evt,mod = {}) {
    const graph = this.graph;
    const item = evt.item;
    const type = item.getType();
    const model = { ...item.getModel(), ...mod };
    const id = model.id;
    graph.removeItem(item, false);
    graph.addItem(type, model);
    //
    const app = graph.get("elsFlowChart");
    // t.setState('selected',true)
    app.apiSetCurrentItem( graph.findById(id),true);
    //
    utils.itemClickSelect.call(this,{item: graph.findById(id)})
  },
  updateCursor(graph, item, cursor = "crosshair") {
    const m = item.getModel();
    const s = m.style;
    graph.updateItem(item, { style: { ...s, cursor } });
  },
  resetItemState(item,name,value){
    const states = item.getStates();
    const has = states.includes(name)
    const bool = value?!has:has;
    if(!bool) return;
    // console.log('-------->',states)
    item.setState(name,value);
    //
    states.forEach(n => {
      if(n !== name){
        item.setState(n,true)
      }
    })
  }
};

const behaviors = [
  {
    name: "graph-common",
    data: {
      //
      click(evt) {
        // evt.propagationStopped = true;
        const item = evt.item;

        const graph = this.graph;
        const app = this.graph.get("elsFlowChart");
        //
        if (item) {
          utils.itemClickSelect.call(this,evt)
          app.apiSetCurrentItem(item,null);     
        }
        // canvas click
        else{
          utils.itemClickSelect.call(this,evt,true);
          // 显示节点列表面板
          // 清除已选元素
          app.apiSetCurrentItem(undefined,false);
          //
          app.chartModel = "auto";
          //
          // 取消创建边
          if (graph.get("createEdgeId")) {
            const eid = graph.get("createEdgeId");
            graph.removeItem(eid);
            graph.set("createEdgeId", undefined);
          }
        }
      },
      //
      mousemove(evt) {
        // 更新边的位置
        const eid = this.graph.get("createEdgeId");
        if (eid && this.graph.findById(eid)) {
          const p = { x: evt.x, y: evt.y };
          this.graph.updateItem(eid, { target: p },false);
        }
        // 解决 圆形 mouse enter 的 bug 
        // if(evt.item && !evt.item.hasState('hover')){
        //   evt.item.setState('hover',true)
        // }
      },
      mouseup(evt) {
        // 取消创建边
        const target = (evt.target.shadowRoot && evt.composed) ? (evt.composedPath()[0] || evt.target) : evt.target
        const name = target.cfg.name;
        const eid = this.graph.get("createEdgeId");
        if (name !== "link-point" && this.graph.findById(eid)) {
          this.graph.removeItem(eid, false);
          this.graph.set("createEdgeId", undefined);
        }
      },
      mouseover(evt){
        if (evt.item && !evt.item.hasState('hover') && !evt.item.hasState('select'))  {
          // evt.item.setState('hover',true)
          utils.resetItemState(evt.item,'hover',true)
        }
      },
      mouseout(evt){
        if (evt.item && evt.item.hasState('hover') && !evt.item.hasState('select')) {
          // evt.item.setState('hover',false)
          utils.resetItemState(evt.item,'hover',false)
          //
        }
      },
      canvasDblclick(evt) {
        const graph = this.graph;
        const app = this.graph.get("elsFlowChart");
        const bool = app.$_callHook('canvas:dblclick',evt,graph)
        !bool && graph.fitView([20, 50]);
      },
      //
      
      // 单击节点 文本进入节点编辑模式 默认
      nodeClick(evt){
        evt.propagationStopped = true;
        const target = (evt.target.shadowRoot && evt.composed) ? (evt.composedPath()[0] || evt.target) : evt.target
        const graph = this.graph;
        const app = graph.get("elsFlowChart");
        const bool = app.$_callHook('node:click',evt,graph)
        if(!bool){
          const t = target.cfg.type;
          if(t == 'text'){
            app.apiSetCurrentItem(evt.item,true);
          }
        }
        
      },

      // 双击节点则对其到视口中心 focusItem(item, animate, animateCfg)
      nodeDblclick(evt) {
        // console.log('--------------nodeDblclick')
        const graph = this.graph;
        const app = this.graph.get("elsFlowChart");
        const bool = app.$_callHook('node:dblclick',evt,graph)
        if(!bool){
          const item = evt.item;
          graph.focusItem(item);
        }
        
        // this.graph.setItemState(evt.item,'spread',true)
      },
      // 双击节点则对其到视口中心 focusItem(item, animate, animateCfg)
      edgeDblclick(evt) {
        // console.log('--------------nodeDblclick')
        const graph = this.graph;
        const app = this.graph.get("elsFlowChart");
        const bool = app.$_callHook('edge:dblclick',evt,graph)
        if(!bool){
          const item = evt.item;
          graph.focusItem(item);
        }
        
        // this.graph.setItemState(evt.item,'spread',true)
      },
      "link-pointMouseenter": function(evt) {
        // console.log(this,evt,evt.item)
        const target = (evt.target.shadowRoot && evt.composed) ? (evt.composedPath()[0] || evt.target) : evt.target
        const attrs = target.attrs;
        const isOut = attrs.output === undefined || attrs.output === true;
        const isIn = attrs.input === undefined || attrs.input === true;
        //
        const graph = this.graph;
        const app = graph.get("elsFlowChart");
        const bool = app.$_callHook('link-point:mouseenter',evt,graph,target,isIn,isOut)
        if(!bool){
          if(isOut){
            target.attr({ fill: "#67C23A" });
          }
          if(isIn){
            target.attr({ fill: "#409EFF" });
          }
        }
        
        
      },
      "link-pointMouseleave": function(evt) {
        // console.log(this,evt,evt.item)
        evt.target.attr({ fill: "transparent" });
      },
      "link-pointMousedown": function(evt) {
        evt.propagationStopped = true;
        //
        const target = (evt.target.shadowRoot && evt.composed) ? (evt.composedPath()[0] || evt.target) : evt.target
        const attrs = target.attrs;
        const isOut = attrs.output === undefined || attrs.output === true;
        if(!isOut) return;
        
        //
        const archor = direction[target.attrs.direction];
        // 需要边的数据
        const id = "edge-" + new Date().getTime();
        //
        const d = {
          name: "edge",
          label:'',
          type: "mike-polyline",
          ...(this.graph.get("lineEdgeData") || {}),
        };
        //
        const edge = {
          id,
          ...d,
          source: evt.item,
          target: { x: evt.x, y: evt.y },
          sourceAnchor: archor,
        };
        //
        const app = this.graph.get("elsFlowChart");
        const bool = app.$_callHook('link-point:mousedown',evt,this.graph,edge)
        if(!bool){
          // 添加边 并且不缓存
          this.graph.addItem("edge", edge, false);
          //
          this.graph.set("createEdgeId", id);
          //
        }
        
        // 清除当前已选元素
        
        utils.itemClickSelect.call(this,{});
        app.apiSetCurrentItem(undefined);
        
      },
      "link-pointMouseup": function(evt) {
        evt.propagationStopped = true;
        const target = (evt.target.shadowRoot && evt.composed) ? (evt.composedPath()[0] || evt.target) : evt.target
        const attrs = target.attrs; // input   | output  | 
        const eid = this.graph.get("createEdgeId");
        const item = this.graph.findById(eid)
        const isIn = attrs.input === undefined || attrs.input === true;
        if (item && item.getSource() != evt.item && isIn) {
          const archor = direction[attrs.direction];
          utils.readdItem.call(this,{item}, {
            target: evt.item,
            targetAnchor: archor,
          });
          //
          this.graph.set("createEdgeId", undefined);
        }
        else if(item){
          this.graph.removeItem(item, false);
          this.graph.set("createEdgeId", undefined);
        }
      },
    },
  },
  {
    name: "add-model",
    data: {
      canvasMousemove(evt) {
        const graph = this.graph;
        const node = graph.get("addModelNode");
        const nodeId = graph.get("addModelNodeId");
        const x = evt.x;
        const y = evt.y;
        if (nodeId) {
          graph.updateItem(nodeId, { x, y }, false);
        } else if (node) {
          const id = "node-" + new Date().getTime();
          const model = { id, x, y, ...node };
          graph.addItem("node", model, false);
          graph.set("addModelNodeId", id);
        }
      },
      nodeMousemove(evt) {
        const graph = this.graph;
        const node = graph.get("addModelNode");
        const nodeId = graph.get("addModelNodeId");
        const x = evt.x;
        const y = evt.y;
        if (nodeId) {
          graph.updateItem(nodeId, { x, y }, false);
        } else if (node) {
          const id = "node-" + new Date().getTime();
          const model = { id, x, y, ...node };
          graph.addItem("node", model, false);
          graph.set("addModelNodeId", id);
        }
      },
      // 放置节点
      nodeClick(evt) {
        const graph = this.graph;
        const nodeId = graph.get("addModelNodeId");
        // console.log(nodeId)
        if (nodeId) {
          utils.readdItem.call(this,evt,{x:Math.round(evt.x),y:Math.round(evt.y)});
          graph.set("addModelNodeId", undefined);
          graph.set("addModelNode", undefined);
          // 清除按钮组选项
          const app = graph.get("elsFlowChart");
          app.currentNodeName = '';
          app.chartModel = "auto";
        }
        // console.log(graph.save())
        // createAdjacencyLists(graph.save());
        // console.log(JSON.stringify(graph.save()))
      },
      // 放置节点
      nodeMouseup(evt){
        const graph = this.graph;
        const nodeId = graph.get("addModelNodeId");
        if (nodeId) {
          utils.readdItem.call(this,evt);
          graph.set("addModelNodeId", undefined);
          graph.set("addModelNode", undefined);
          // 清除按钮组选项
          const app = graph.get("elsFlowChart");
          app.currentNodeName = '';
          app.chartModel = "auto";
        }
      },
      // 取消
      nodeContextmenu(evt) {
        const graph = this.graph;
        const nodeId = graph.get("addModelNodeId");
        if (nodeId) {
          graph.removeItem(nodeId,false);
          graph.set("addModelNodeId", undefined);
          graph.set("addModelNode", undefined);
          // 清除按钮组选项
          const app = graph.get("elsFlowChart");
          app.currentNodeName = '';
        }
      },
      //
    },
  },
  
];

const getOptions = (data) => {
  const evts = {};
  const keys = Object.keys(data);
  keys.forEach((k) => {
    const ek = k.replace(/([A-Z])/g, ":$1").toLowerCase();
    evts[ek] = k;
  });
  // console.log(evts)
  const ops = {
    getEvents() {
      return { ...evts };
    },
    ...data,
  };

  return ops;
};
// 注册交互事件
export const registBehaviors = (G6) => {
  behaviors.forEach((obj) => {
    const name = obj.name;
    const data = obj.data;
    const option = getOptions(data);
    // console.log(option)
    G6.registerBehavior(name, option);
    //
  });
};
