<!-- 流程图组件版本2 -->
<template>
  <div class="els-flow-chart">
    <!-- 菜单栏 -->
    <div class="fc--menus">
      <!-- 编辑器图标 -->
      <div class="m--left">
        <div class="m--title" v-if="title">
          <i class="t--icon el-icon-setting"></i>
          <b class="t--text">{{ title }}</b>
        </div>
        <!-- slot -->
        <div class="ml--slot">
          <slot></slot>
        </div>

        <!-- 模式菜单 -->
        <template v-if="menus.model.length > 0">
          <el-divider direction="vertical"></el-divider>
          <els-button-group
            :column="menus.model"
            :delimiter="true"
            active
            :currentActive.sync="chartModel"
          ></els-button-group>
        </template>
      </div>
      <div class="m--center"></div>
      <div class="m--right" v-show="graphModel == 'design'">
        <el-button
          class="m--delete-item"
          icon="el-icon-delete"
          type="danger"
          size="mini"
          :disabled="!currentItem"
          @click="handleDeleteCurrentItem"
          ></el-button
        >
        <!-- 功能菜单 tools -->
        <template v-if="menus.tools.length > 0">
          <el-divider direction="vertical"></el-divider>
          <els-button-group
            :column="menus.tools"
            :delimiter="true"
            @click="handleFunctionClick"
          ></els-button-group>
        </template>
        <!-- 图形菜单 tools -->
        <template v-if="menus.graph.length > 0">
          <el-divider direction="vertical"></el-divider>
          <els-button-group
            :column="menus.graph"
            :delimiter="true"
            @click="handleFunctionClick"
          ></els-button-group>
        </template>
        <!-- 其他菜单 -->
        <template v-if="menus.setting.length > 0 && showSetting">
          <el-divider direction="vertical"></el-divider>
          <els-button-group
            :column="menus.setting"
            :delimiter="true"
            @click="handleFunctionClick"
          ></els-button-group>
        </template>
      </div>

      <!--  -->
    </div>
    <!-- 分栏布局 - 两栏 -->
    <div class="fc--main">
      <!-- 节点列表 -->
      <div class="m--nodes" v-show="graphModel == 'design'">
        <!-- 节点搜索 -->
        <div class="mn--list-search">
          <el-input v-model="nodeListSearchValue" prefix-icon="el-icon-search" size="mini" placeholder="请输入节点标签搜索"></el-input>
        </div>
        <!--  -->
        <div class="mn--list">
          <template v-for="item in nodes">
            <fieldset :key="item.name">
              <legend>{{ item.title }}</legend>
              <els-button-group
                    :column="item.data"
                    :active="addNodeModel == 'click' ? 'name' : false"
                    :currentActive.sync="currentNodeName"
                    @click="handleNodeSelect"
                    @mousedown="handleNodeDown"
              ></els-button-group>
            </fieldset>
          </template>
        </div>
      </div>
      <!-- 画布 -->
      <div
        class="m--canvas"
        ref="canvasContainer"
        @contextmenu.prevent="() => false"
      >
        <!-- g6 容器 -->
        <div id="graphContainer"></div>
      </div>
      <div class="m--form" v-show="graphModel == 'design'">
        <!-- 属性面板 - 弹出框容器 -->
        <els-drawer
          class="form-slider"
          :visible.sync="propertyPanelVisible"
          :showHeader="false"
        >
          <!-- 图配置表单 -->
          <div class="fs--content" v-if="isGraphSetting">
            <el-tabs v-model="currentTabName" type="card">
              <el-tab-pane
                v-for="item in graphConfigForm"
                :key="item.name"
                :label="item.label"
                :name="item.name"
              >
                <div class="c--form-content" v-if="currentTabName == item.name">
                  <els-form
                    :key="currentItemId + '--' + item.name"
                    v-bind="formOptions"
                    :column="item.column"
                    v-model="graphConfigFormData[item.name]"
                    @change="
                      v => {
                        handleGraphConfigChange(v, item.name);
                      }
                    "
                  ></els-form>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 当前元素属性面板 -->
          <div class="fs--content" v-else>
            <el-tabs v-model="currentTabName" type="card" >
              <el-tab-pane
                v-for="item in currentItemForm"
                :key="item.name"
                :label="item.label"
                :name="item.name"
              >
                <div class="c--form-content" v-if="currentTabName == item.name && propertyPanelVisible">
                  <els-form
                    :key="currentItemId + '--' + item.name"
                    v-bind="formOptions"
                    :column="item.column"
                    v-model="graphFormData[currentItemId][item.name]"
                    :ctx="elsFormContext"
                    @change="
                      v => {
                        handlePropertyChange(v, item.name);
                      }
                    "
                  ></els-form>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </els-drawer>
      </div>
    </div>
  </div>
</template>

<script>

//
import G6 from "@antv/g6";
import {
  graphConfig,
  graphOn,
  graphConfigForm,
  defautGraphConfigData,
  menus,
  modelDataKeys,
  defaultElemNames,
  defaultElemForm,
  defaultElemGraphForm,
} from "./config.js";
import { regiestItems, nodes } from "./nodes.js";
import { registBehaviors } from "./behaviors.js";

export default {
  name: "els-flow-chart",
  props: {
    // 功能配置
    options: { type: Object, default: () => ({}) },
    // 流程图标题
    title:String,
    // 是否使用默认的 nodes
    useDefaultNodes: { type: Boolean, default: true },
    // 外部数据{元素数据，form 数据，默认表单数值} ,返回参数格式：{nodes:[],edges:[],forms:{},data:{}}
    itemsData: { type: Object, default: () => ({}) },
    // 图形数据，包含图数据，图表单数据 [图形数据，表单数据] [data,formData]
    graphData: Array,
    // 图模式  [design | view] 设计模式，查看模式
    graphModel: { type: String, default: "design" },
    //
    graphConfigData: { type: Object, default: () => ({}) },
    //
    itemStates:Object,
    // 是否自动添加图形表单
    autoAddGraphForm:{type:Boolean,default:false},
    // 是否显示图形配置功能集合
    showSetting:Boolean,
    // 添加节点的模式 默认 click 可选 drag
    addNodeModel:{type:String,default:'click'},
    // hooks 钩子函数
    hooks:Object,
    extendNodes:Object, //  扩展节点  {nodes=[{name,options,type}],edges:[{name,options,type}]}
  },
  data: () => ({
    graphApp: undefined,
    chartModel: "auto",
    nodesPanelVisible: true,
    propertyPanelVisible: false,
    currentItem: undefined,
    currentNodeName: "", // 节点列表节点名称
    currentTabName: "graph", //  tab 页 tab 名称
    graphInitData: undefined,
    graphFormData: {},
    graphConfigFormData: { ...defautGraphConfigData },
    isGraphSetting: false, // 是否显示图配置表单
    nodeListSearchValue:'',// 节点列表搜索条件
    
  }),
  computed: {
    $_HOOKS(){
      return this.hooks || {};
    },
    menus() {
      return menus;
    },
    nodes() {
      // 获取系统默认 nodes
      const dns = this.useDefaultNodes ? nodes : [];
      // 获取外部 nodes
      const extNodes = this.itemsData.nodes || [];
      //
      const list = [...dns, ...extNodes];
      if(this.nodeListSearchValue){
        // console.log(list)
        return list.map(item => ({...item,data:item.data.filter(n => n.label.includes(this.nodeListSearchValue))})).filter(item => item.data && item.data.length > 0) //.filter(item => item.data && item.data.length > 0)
      }
      return list;
    },
    //
    graphConfigForm() {
      return [...graphConfigForm];
    },
    //
    currentItemModel() {
      return this.currentItem ? this.currentItem.getModel() : undefined;
    },
    currentItemId() {
      return this.currentItemModel ? this.currentItemModel.id : undefined;
    },
    currentItemName() {
      return this.currentItemModel ? this.currentItemModel.name : "";
    },
    currentItemForm() {
      if(!this.currentItemId || !this.propertyPanelVisible) return [];
      const n = this.currentItemName;
      const bool = defaultElemNames.includes(n) && (!this.itemsData || !this.itemsData.forms || !this.itemsData.forms[n]);
      if (bool) {
        return [...defaultElemForm];
      } else {
        const d = (this.itemsData.forms || {})[n] || [];
        // d.push(f);
        return this.autoAddGraphForm ? [...d,defaultElemGraphForm] : [...d];
        // return d
      }
    },
    formOptions() {
      // form 配置
      const elForm = {
        size: "mini",
        labelWidth: "120px",
        labelPosition:'top'
      };
      return { elForm };
    },
    itemsModel(){
      const {nodes = [],edges = [],combos = []} = this.itemsData;
      const res = {};
      const items = [...nodes,...edges,...combos]
      items.forEach(n => {
        const cols = n.data
        cols.forEach(e => {
          
          const m = e.model || {};
          const k = m.name;
          //
          res[k] = m;
        })
      })
      return res;
    },
    elsFormContext(){
      return {elsFlowChart:this}
    }
  },
  methods: {
    // hook 的调用
    $_callHook(n,...args){
      let h = this.$_HOOKS[n] || [];
      const fns = [].concat(h);
      //
      if (fns.length === 0) {
        return false;
      }
      let res = true;
      fns.forEach((f) => {
        if (typeof f === "function") {
          const b = f.call(this, ...args) == undefined ;
          res = b == undefined ? res : res && b;
        }
      });
      return res;
    },
    // 初始化
    init() {
      this.graphConfigFormData = {
        ...this.graphConfigFormData,
        ...this.graphConfigData,
      };
    },
    // graph init
    graphInit() {
      // 重置画布大小
      this.apiResetSize();
      // 将当前实例保存到 graph 对象中
      this.graphApp.set("elsFlowChart", this);
      // 添加配置的监听事件
      Object.keys(graphOn).forEach((k) => {
        const f = graphOn[k];
        this.graphApp.on(k, f);
      });
      // 初始化 图配置数据
      this.utilSetGraphConfig();
      // 初始化图形数据
      this.graphInitData && this.apiReadData(this.graphInitData)
      //

    },
    // 功能菜单
    handleFunctionClick(p) {
      const { name } = p.data;
      switch (name) {
        case "undo":
          this.utilUndo();
          break;
        case "redo":
          this.utilRedo();
          break;
        case "zoom-in":
          this.graphApp &&
            this.graphApp.zoom(2, {
              x: this.graphApp.cfg.width / 2,
              y: this.graphApp.cfg.height / 2,
            });
          // this.graphApp.fitCenter()
          break;
        case "zoom-out":
          this.graphApp &&
            this.graphApp.zoom(0.5, {
              x: this.graphApp.cfg.width / 2,
              y: this.graphApp.cfg.height / 2,
            });
          break;
        case "submit":
          this.utilEmitChange();
          break;
        case "setting":
          this.currentItem = undefined;
          this.propertyPanelVisible = true;
          this.isGraphSetting = true;
          this.utilResetTabName("edgeConfig");
          break;
      }
    },
    // 节点表点击事件
    handleNodeSelect(p) {
      // console.log('mouse-click ---> ',p)
      if(this.addNodeModel == 'click'){
        this.chartModel = "add";
        this.graphApp.set("addModelNode", p.data.model);
      }
    },
    //
    handleNodeDown(p){
      // console.log('mouse-down ---> ',p)
      if(this.addNodeModel == 'drag'){
        this.chartModel = "add";
        this.graphApp.set("addModelNode", p.data.model);
      }
    },
    // 表单 change 事件
    handlePropertyChange(v, name) {
        const { label, key,description } = v;
        const m = {};
        if(label !== undefined){m.label = label;}
        if(key !== undefined){m.key = key}
        if(description != undefined){m.description = description}
        this.utilUpdateCurrentItem(m);
    },
    // 图配置表单 change 事件
    handleGraphConfigChange(v, name) {
      this.utilSetGraphConfig();
    },
    // 删除当前元素
    handleDeleteCurrentItem() {
      if (!this.currentItemId) return;
      const id = this.currentItemId;
      this.apiSetCurrentItem();
      this.graphApp && this.graphApp.removeItem(id);
    },
    // 重置 graphdata 当前节点的数据
    resetGraphFormData() {
      const id = this.currentItemId;
      if (this.graphFormData[id]) return;
      const name = this.currentItemName;
      const fns = this.currentItemForm.map((item) => item.name);
      const model = this.currentItemModel;
      const data = this.itemsData.data ? this.itemsData.data[name] || {} : {};
      // const td = this.graphData && this.graphData[1][id] ? this.graphData[1][id] : {};
      const md = Object.assign(
        {},
        ...modelDataKeys.map((k) => {
          const d = {};
          d[k] = model[k];
          return d;
        })
      );
      const res = {};
      fns.forEach((n) => {
        const d = data[n] || {};
        // const t = td[n] || {};
        res[n] = {  ...d,...md};
      });
      //
      this.graphFormData[id] = res;
    },
    
    // 重置 tab  name
    utilResetTabName(n) {
      n = n || (this.currentItemForm.length > 0 && this.currentItemForm[0].name) || 'graph';
      // console.log('..............',n,this.currentItemForm)
      this.currentTabName = n;
    },
    //
    utilSaveData() {
      // 图形数据
      const d = this.graphApp ? this.graphApp.save() : {};
      // console.log('===save===',JSON.stringify(d))
      //
      d.nodes = d.nodes ? d.nodes.map(n => ({x:n.x,y:n.y,name:n.name,label:n.label,type:n.type,key:n.key,id:n.id})) :[]
      d.edges = d.edges ? d.edges.map(n => ({id:n.id,name:n.name,label:n.label,type:n.type,source:n.source,target:n.target,key:n.key,sourceAnchor:n.sourceAnchor,targetAnchor:n.targetAnchor})) : []
      // 表单数据
      const f = { ...this.graphFormData };
      //
      return [d, f];
    },
    //
    utilEmitChange() {
      const r = this.utilSaveData();
      //
      this.$emit("submit", r);
    },
    // 撤销
    utilUndo() {
      // 清除当前已选节点
      this.utilClearCurrentItem();
      //
      const graph = this.graphApp;
      const undoStack = graph.getUndoStack();

      if (!undoStack || undoStack.length === 0) {
        return;
      }

      const currentData = undoStack.pop();
      if (currentData) {
        const { action } = currentData;
        graph.pushStack(
          action,
          JSON.parse(JSON.stringify(currentData.data)),
          "redo"
        );
        let data = currentData.data.before;

        if (action === "add") {
          data = currentData.data.after;
        }

        if (!data) return;

        switch (action) {
          case "visible": {
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                const item = graph.findById(model.id);
                if (model.visible) {
                  graph.showItem(item, false);
                } else {
                  graph.hideItem(item, false);
                }
              });
            });
            break;
          }
          case "render":
          case "update":
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                graph.updateItem(model.id, model, false);
              });
            });
            break;
          case "changedata":
            graph.changeData(data, false);
            break;
          case "delete": {
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                const itemType = model.itemType;
                delete model.itemType;
                graph.addItem(itemType, model, false);
              });
            });
            break;
          }
          case "add":
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                graph.removeItem(model.id, false);
              });
            });
            break;
          case "updateComboTree":
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                graph.updateComboTree(model.id, model.parentId, false);
              });
            });
            break;
          default:
        }
      }
    },
    // 恢复 撤销
    utilRedo() {
      // 清除当前已选节点
      this.utilClearCurrentItem();
      //
      const graph = this.graphApp;
      const redoStack = graph.getRedoStack();

      if (!redoStack || redoStack.length === 0) {
        return;
      }

      const currentData = redoStack.pop();
      if (currentData) {
        const { action } = currentData;
        let data = currentData.data.after;
        graph.pushStack(action, JSON.parse(JSON.stringify(currentData.data)));
        if (action === "delete") {
          data = currentData.data.before;
        }

        if (!data) return;

        switch (action) {
          case "visible": {
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                const item = graph.findById(model.id);
                if (model.visible) {
                  graph.showItem(item, false);
                } else {
                  graph.hideItem(item, false);
                }
              });
            });
            break;
          }
          case "render":
          case "update":
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                graph.updateItem(model.id, model, false);
              });
            });
            break;
          case "changedata":
            graph.changeData(data, false);
            break;
          case "delete":
            if (data.edges) {
              data.edges.forEach((model) => {
                graph.removeItem(model.id, false);
              });
            }
            if (data.nodes) {
              data.nodes.forEach((model) => {
                graph.removeItem(model.id, false);
              });
            }
            if (data.combos) {
              data.combos.forEach((model) => {
                graph.removeItem(model.id, false);
              });
            }
            break;
          case "add": {
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                const itemType = model.itemType;
                delete model.itemType;
                graph.addItem(itemType, model, false);
              });
            });
            break;
          }
          case "updateComboTree":
            Object.keys(data).forEach((key) => {
              const array = data[key];
              if (!array) return;
              array.forEach((model) => {
                graph.updateComboTree(model.id, model.parentId, false);
              });
            });
            break;
          default:
        }
      }
    },
    //
    // 解析 graph config form data 并设置相关值
    utilSetGraphConfig() {
      const { edgeConfig } = this.graphConfigFormData;
      // edge
      if (edgeConfig) {
        this.graphApp.set("lineEdgeData", { ...edgeConfig });
      }
    },
    // 清除当前已选节点
    utilClearCurrentItem() {
      //
      const csid = this.graphApp && this.graphApp.get("currentSelectId");
      if (csid && csid == this.currentItemId) {
        this.graphApp.set("currentSelectId", undefined);
      }
      //
      this.apiSetCurrentItem();
    },
    // 更新当前节点数据
    utilUpdateCurrentItem(model = {}) {
      const bool = this.graphApp && this.currentItem;
      bool && this.graphApp.updateItem(this.currentItemId, model,false);
    },
    //
    apiReadData(d) {
      //
      this.$_callHook('beforeRead');
      // console.log('...........',JSON.stringify(d))
      // setTimeout(() => {
      //   this.graphApp && this.graphApp.changeData(d,false) && this.graphApp.refresh()
      // },1000)
      if(this.graphApp && d && d.nodes){
        const {edgeConfig} = defautGraphConfigData;
        const models = this.itemsModel;
        // console.log(models)
        const {nodes,edges = [],combos = []} = d;
        //
        const ns = nodes.map(n => {
          const name = n.name;
          const em = models[name] || {}
          return {...em,...n}
        })
        const es = edges.map(e => {
          const name = e.name;
          const em = models[name] || {}
          return {...edgeConfig,...em,...e}
        })
        const cs = combos.map(c => {
          const name = c.name;
          const em = models[name] || {}
          return {...em,...c}
        })
        const data = {nodes:ns,edges:es,combos:cs}
        //
        //
        this.graphApp.read(data)
        // combos.forEach(item => this.graphApp.addItem('edge',item,false))
        //
        // this.graphApp.fitView(100);
        //
        this.$_callHook('readed');
      }

    },
    // api
    apiSetCurrentItem(item, bool = false) {
      this.isGraphSetting = false;
      if(bool != undefined){
        this.propertyPanelVisible = bool
      }
      if (item) {
        if(this.currentItem != item){
          // console.log('----------------',item,bool)
          this.currentItem = item;
        //
          this.resetGraphFormData();
        }
        this.utilResetTabName();
      } else {
        this.currentItem = undefined;
      }
      
      
    },
    // 重置画布大小 默认自动获取
    apiResetSize(width,height){
      const c = this.$refs["canvasContainer"];
      const w = width || (c && c.offsetWidth) || 800;
      const h = height || (c && c.offsetHeight) || 500;
      //
      this.graphApp && this.graphApp.changeSize(w,h)
    },
    // # 设置元素状态
    apiSetState(id,name,value){
      if(!id || !this.graphApp.findById(id)) return;
      const item = this.graphApp.findById(id);
      const isHas = item.hasState(name)
      if(isHas == value) return;
      item.setState(name,value);
    },
  },
  watch: {
    graphData(v) {
      if(!v || v.length == 0) return;
      const d = v[0] || {};
      const f = v[1] || {};
      this.graphInitData = d;
      this.graphFormData = { ...this.graphFormData, ...f };
      //

      this.apiReadData(d);
    },
    //
    chartModel(v) {
      this.graphApp.setMode(v);
      this.graphApp.set("chartModel", v);
      this.utilResetTabName();
    },
  },
  //
  created() {
    this.$_callHook('beforeCrate');
    // 注册元素
    regiestItems(G6,extendNodes);
    // 注册 行为
    registBehaviors(G6);
    // 如果当前非 设计模式，则切换至 view 模式
    this.chartModel = this.graphModel == "design" ? "auto" : "view";
    //
    this.init();
    this.$_callHook('created');
  },
  mounted() {
    // const c = this.$refs["canvasContainer"];
    // const w = (c && c.offsetWidth) || 800;
    // const h = (c && c.offsetHeight) || 500;
    //
    this.$_callHook('beforeMount');
    //
    const grid = new G6.Grid();
    //
    this.graphApp = new G6.Graph({
      container: "graphContainer",
      // width: w,
      // height: h,
      ...graphConfig,
      plugins: [grid],
    });
    //
    // this.graphApp.data(this.testData);
    this.graphApp.setMode(this.chartModel);
    //
    this.graphApp.render();
    //
    this.graphInit();
    //
    this.$_callHook('mounted');
  },
  destroyed(){
    // console.log('destroyed ---- ')
  }
};
</script>

<style scoped lang="scss">
.els-flow-chart {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .fc--menus {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    height: 40px;
    background: #f0f2f5;
    padding:0 5px;
    // padding: 0 10px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
    }
    .m--title {
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 20px;
      .t--icon {
        font-size: 30px;
        font-weight: 600;
        margin-right: 5px;
      }
      .t--text {
        font-size: 22px;
      }
    }

    ::v-deep .els-button-group {
      height: 32px;
      display: flex;
      align-items: center;
      background: #fff;
      border: 1px solid #ddd;
      box-sizing: border-box;
      // margin-right: 10px;
      border-radius: 4px;
      overflow: hidden;
      &.m--delete-item .el-button {
        width: auto;
        padding: 0 10px;
      }
      .el-button {
        width: 32px;
        height: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: none;
      }
      .g--delimiter {
        border-left: 1px solid #eee;
        height: 100%;
      }
    }
  }
  .fc--main {
    box-sizing: border-box;
    height: calc(100% - 40px);
    // background: #eee;
    display: flex;
    .m--nodes {
      position: relative;
      // background: #fff;
      height: 100%;
      padding:5px;
      box-sizing: border-box;
      .mn--list-search{
        height:40px;
        box-sizing: border-box;
        border-bottom:1px solid #ddd;
      }
      .mn--list{
        position: relative;
        height:calc(100% - 40px);
        overflow:auto;
        fieldset{
        // height: 100%;
        border-color: #f0f2f5;
        border-radius: 8px;
        border-style: dashed;
        padding: 10px;
        background-color: #00000005;
        legend{
          color: #999;
          font-size: 12px;
        }
        ::v-deep .els-button-group{
          display:flex;
          flex-direction: column;
          justify-content: center;
          .el-button{
            padding:10px 2px;
            border:none;
            margin:5px 0;
          }
        }
      }
      }
      
    }
    .m--canvas {
      flex:1;
      position: relative;
      height: 100%;
      z-index: 0;
      &.cursor--arrow {
        cursor: default;
      }
      &.cursor--drag {
        cursor: grab;
      }
      &.cursor--edit {
        cursor: default;
      }
      &.cursor--view {
        cursor: move;
      }
      &.cursor--line {
        cursor: crosshair;
      }
      // &.cursor--drag{}
      // background:#f0f2f5;
      // overflow: hidden;
    }
    .m--form {
      position: relative;
      height: 100%;
      .form-slider {
        // width:auto;
        width:50vw;
        min-width: 1000px;
        user-select: none;
        ::v-deep .els-drawer--content {
          border-radius: 0;
          user-select: none;
          .c--body {
            padding: 0;
            .fs--content {
              height: 100%;
              .el-tabs--card {
                height: 100%;
                .el-tabs__header {
                  margin: 0;
                  // .el-tabs__nav{
                  //   margin-left:107px;
                  // }
                }
                .el-tabs__content {
                  height: calc(100% - 41px);
                  box-sizing: border-box;
                  padding: 10px;
                  overflow: auto;
                  .el-tab-pane {
                    height: 100%;
                    .c--form-content,.els-form{
                      height:100%;
                    }

                    .els-form .el-form .el-form-item {
                      margin-bottom: 18px;
                    }
                    .el-form{
                      height:100%;
                      display:flex;
                      flex-direction: column;

                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
