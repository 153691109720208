
import {getShapeSize,changeShapeSize,getType} from "./util.js"
// 节点数据包含节点的 model 数据，model 数据用于渲染图形用的，节点表单数据 form ，用于生成节点的表单，表单默认数据，即表单的默认数据项
// 每个节点的 from 分为 普通的 form 和扩展节点的 from  主要关注的时扩展的 form 扩展 from 会有默认的数据，默认数据存储再另外一个对象中，编辑模式下下除了更改label 之外，其他属性不允许修改，
/* *
* 1. form 是和节点数据绑定的，依据节点 name 或者 key 值来设定，暂定 name
* 2. model 数据除了 label 外不允许变更，包括坐标位置~特殊节点也不能变更 label 标签，label 无法变更信息要放在 model 数据中
* 3. 表单 js 类型脚本采用 new Function | new AsyncFunction 来执行，表单设计如下 {id:[节点 ID],type:[脚本类型],code:[脚本代码],func:Function|AsyncFunction}  // var AsyncFunction = Object.getPrototypeOf(async function(){}).constructor;
* 4. 执行上下文设计 {$input:输入变量集合,默认是父节点脚本返回值,$common:公共常量或者变量集合，$result:脚本代码执行返回值,$config:配置项}
* 5. API 调用设计，系统会将 公共 ajax 方法放入 $common 中，命名为 $ajax , 调用方式 如果要使用 $ajax 建议使用 AsyncFunction ,func 默认用 Function ,多节点默认用 AsyncFunction ,
* 6. $ajax 使用方式和外部 APi 一致 const res = await $ajax({url:'',method:'',params:'',data:''})
* 7. edge 边的 js脚本 默认全是 return true 边的脚本参与计算，只有返回值为 true 时才会进入下一个节点，否则不会执行到下一个节点~
* 8. --废弃|任何节点都可以作为开始节点只是没有开始节点的功能而已！：任何一个工作流，开始节点都是必须的，结束节点不是必须的。
* 9. 开始节点中返回值会进入 $commons 中，成为全局公共变量或常量，并且会默认添加部分系统变量 如 $ajax 
* 10. 如果要使用其他语言，所有脚本都需要自定义，或者修改默认配置项，推荐修改默认配置项，
* 11. 开始节点中可以修改默认配置项，配置项是一个 json 格式的数据，如语言类型，边的默认脚本等数据~开始节点有固定的模板 json 依旧利用 editor 来完成，语言被固定为 javascript 实质是一个 json 对象 
* 12. 一个工作流中只允许有一个开始节点，且所有节点都有线连接，不允许无连接的节点存在或者无连接的节点不参与执行器调度。
* 13. 一个工作流可调用另一个已存在的工作流，调用方式可选择同步或者异步,选择同步，会等待调用结束才会执行下一个节点，选择异步，不会等待调用结束即可执行下一个节点，
* 14. 系统内置节点有：开始节点，ajax 调用节点，工作流调用节点，判断节点，执行节点，结束节点 | 未来扩展的 渲染节点，沙箱节点，echart 节点
* 15. 新建流程图|工作流 表单属性设计：{name:[工作流名称],desc:[工作流描述],author:[创建者]}
* 16. 开始节点属性配置：{lang:[语言类型],uriBase:[api url 基础地址],getFlowNames:[获取工作流 Name 集合],getFlowDataByName:[依据 name 获取 工作流数据]}
*/ 
const anchorPoints = [
  [0.5, 0],
  [1, 0.5],
  [0.5, 1],
  [0, 0.5],
];
const linkPoints = { top: true, right: true, bottom: true, left: true };
const stateStyles = {
  hover: {
    lineWidth: 2,
    lineDash: [0, 0],
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    radius: 0,
    opacity: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    shadowColor: "#0DC0F7",
    stroke: "#1BE4DD",
  },
  select: {
    lineWidth: 2,
    lineDash: [0, 0],
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    radius: 0,
    opacity: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    shadowColor: "#4290FE",
  },
  undo: {
    lineWidth: 1,
    lineDash: [0, 0],
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    radius: 0,
    opacity: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    stroke: "#F4B223",
    shadowColor: "#F5C126",
  },
  success: {
    lineWidth: 1,
    lineDash: [0, 0],
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    radius: 0,
    opacity: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    stroke: "#15F95E",
    shadowColor: "#23F75F",
  },
  fail: {
    lineWidth: 1,
    lineDash: [0, 0],
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    radius: 0,
    opacity: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    stroke: "#F82112",
    shadowColor: "#F71515",
  },
};

const models = [
  {
    type: "mike-circle",
    label: "开始",
    name:'start',
    size: 50,
    style: { fill: "#CCF49F", stroke: "#CEF8AE",cursor:'move' },
    labelCfg: {
      style: { fill: "#0ACF55", shadowColor: "#1CE7EE", shadowBlur: 20,cursor:'pointer' },
    },
    //
    // stateStyles: { ...stateStyles },
    anchorPoints: [...anchorPoints],
    links: { ...linkPoints },
  },
  {
    type: "mike-rect",
    label: "API 调用",
    name:'ajax',
    size: [100, 50],
    style: { fill: "#F7C9FB", stroke: "#EAAAF2" ,cursor:'move' },
    labelCfg: {
      style: {
        shadowBlur: 20,
        fill: "#AE12DD",
        shadowColor: "#890AD2",
        cursor:'pointer' 
      },
    },
    // stateStyles: { ...stateStyles },
    anchorPoints: [...anchorPoints],
    links: { ...linkPoints },
  },
  {
    type: "mike-rect",
    label: "工作流调用",
    name:'schedule',
    size: [100, 50],
    style: { fill: "#F7C9FB", stroke: "#EAAAF2" ,cursor:'move' },
    labelCfg: {
      style: {
        shadowBlur: 20,
        fill: "#AE12DD",
        shadowColor: "#890AD2",
        cursor:'pointer' 
      },
    },
    // stateStyles: { ...stateStyles },
    anchorPoints: [...anchorPoints],
    links: { ...linkPoints },
  },
  {
    type: "mike-diamond",
    label: "判断",
    name:'judge',
    size: [90, 50],
    style: { fill: "#9EF4F7", stroke: "#60F0F5",cursor:'move'  },
    labelCfg: {
      style: { shadowBlur: 20, fill: "#119AC4", shadowColor: "#1C82EE" ,cursor:'pointer' },
    },
    // stateStyles: { ...stateStyles },
    anchorPoints: [...anchorPoints],
    links: { ...linkPoints },
  },
  {
    type: "mike-rect",
    label: "执行",
    name:'do',
    size: [100, 50],
    style: { fill: "#F7C9FB", stroke: "#EAAAF2" ,cursor:'move' },
    labelCfg: {
      style: {
        shadowBlur: 20,
        fill: "#AE12DD",
        shadowColor: "#890AD2",cursor:'pointer' 
      },
    },
    // stateStyles: { ...stateStyles },
    anchorPoints: [...anchorPoints],
    links: { ...linkPoints },
  },
  {
    type: "mike-circle",
    label: "结束",
    name:'end',
    size: 50,
    style: { fill: "#F2D658", stroke: "#F5C160" ,cursor:'move' },
    labelCfg: {
      style: { shadowBlur: 20, fill: "#F15716", shadowColor: "#EE3F1C",cursor:'pointer'  },
    },
    // stateStyles: { ...stateStyles },
    anchorPoints: [...anchorPoints],
    links: { ...linkPoints },
  },
]

//
const nodes = [
  {title:'默认',name:'base',data:{
    column:[
      {name:'start',model:{...models[0]},icon:'',label:'开始'},
      {name:'ajax',model:{...models[1]},icon:'',label:'AJAX'},
      {name:'schedule',model:{...models[2]},icon:'',label:'调度器'},
      {name:'judge',model:{...models[3]},icon:'',label:'判断'},
      {name:'do',model:{...models[4]},icon:'',label:'执行'},
      {name:'end',model:{...models[5]},icon:'',label:'结束'}
    ]
  }}
]
// 通用表单 工厂函数
const formFunction = (config = {}) => {
  const key = config.key || {}
  const label = config.label || {}
  const script = config.script || {langsDisabled: false,title: "脚本",prop: "script",}
  return [
    
    {
      label:'脚本属性',
      name:'script',
      column:[
        {
          tag: "form-group",
          title: "脚本编辑器",
          style: { height: "400px" },
          cls: [
            {
              tag: "els-monaco-editor",
              ...script
            },
          ],
        }
      ]
    },
    {
      label: "图形属性",
      name: "graph",
      alias: "editNodeBase",
      column: [
        {
          tag: "form-group",
          title: "初始属性配置",
          style: { height: "400px" },
          cls: [
            {label:'节点名称',prop:'name',disabled:true},
            { label: "唯一 key", prop: "key" ,...key},
            { label: "节点标签", prop: "label" ,...label},
          ]
        }
      ],
    },
  ]
}
// 初始节点配置项及默认值
const config = {
  LANGUAGE:'javascript', 
  URI_BASE:'',
  GET_FLOW_NAMES:() => [],
  GET_FLOW_DATA:($name) => {return {}},
}
//
const itemsForm = {
  start:[...formFunction({label:{disabled:true},script:{prop:'config',title:'属性配置',lang:'json'}})],
  end:[...formFunction()],
  ajax:[...formFunction()],
  schedule:[...formFunction()],
  judge:[...formFunction()],
  do:[...formFunction()],
  edge:[...formFunction()]
}

const itemsFormData = {
  start:{config:JSON.stringify(config)},
  end:{},
  ajax:{label:'',},
  judge:{},
  do:{},
  edge:{script:{code:'return true;'}}
}

// 官方内置动画
const lineDash = [4, 2, 1, 2];
const elemNames = ['link-point','text-shape']
const animation = {
  // nodes:{
  //   back:{
  //     afterDraw(cfg, group) {
  //       if(!cfg.animate || cfg.animate != 'back') return;
  //       const r = cfg.size / 2;
  //       const back1 = group.addShape('circle', {
  //         zIndex: -3,
  //         attrs: {
  //           x: 0,
  //           y: 0,
  //           r,
  //           fill: cfg.color,
  //           opacity: 0.6,
  //         },
  //         name: 'back1-shape',
  //       });
  //       const back2 = group.addShape('circle', {
  //         zIndex: -2,
  //         attrs: {
  //           x: 0,
  //           y: 0,
  //           r,
  //           fill: cfg.color,
  //           opacity: 0.6,
  //         },
  //         name: 'back2-shape',
  //       });
  //       const back3 = group.addShape('circle', {
  //         zIndex: -1,
  //         attrs: {
  //           x: 0,
  //           y: 0,
  //           r,
  //           fill: cfg.color,
  //           opacity: 0.6,
  //         },
  //         name: 'back3-shape',
  //       });
  //       group.sort(); // Sort according to the zIndex
  //       back1.animate(
  //         {
  //           // Magnifying and disappearing
  //           r: r + 10,
  //           opacity: 0.1,
  //         },
  //         {
  //           duration: 3000,
  //           easing: 'easeCubic',
  //           delay: 0,
  //           repeat: true, // repeat
  //         },
  //       ); // no delay
  //       back2.animate(
  //         {
  //           // Magnifying and disappearing
  //           r: r + 10,
  //           opacity: 0.1,
  //         },
  //         {
  //           duration: 3000,
  //           easing: 'easeCubic',
  //           delay: 1000,
  //           repeat: true, // repeat
  //         },
  //       ); // 1s delay
  //       back3.animate(
  //         {
  //           // Magnifying and disappearing
  //           r: r + 10,
  //           opacity: 0.1,
  //         },
  //         {
  //           duration: 3000,
  //           easing: 'easeCubic',
  //           delay: 2000,
  //           repeat: true, // repeat
  //         },
  //       ); // 3s delay
  //     }
  //   }
  // },
  // edges:{
  //   // 虚线动画 类似蚁行线
  //   dash:{
  //     afterDraw(cfg, group) {
  //       if(!cfg.animate) return;
  //       // get the first shape in the group, it is the edge's path here=
  //       const shape = group.get('children')[0];
  //       let index = 0;
  //       // Define the animation
  //       shape.animate(
  //         () => {
  //           index++;
  //           if (index > 9) {
  //             index = 0;
  //           }
  //           const res = {
  //             lineDash,
  //             lineDashOffset: -index,
  //           };
  //           // returns the modified configurations here, lineDash and lineDashOffset here
  //           return res;
  //         },
  //         {
  //           repeat: true, // whether executes the animation repeatly
  //           duration: 3000, // the duration for executing once
  //         },
  //       );
  //     }
  //   },
  //   run:{
  //     afterDraw(cfg, group) {
  //       if(!cfg.animate || cfg.animate != 'run') return;
  //       // get the first shape in the group, it is the edge's path here=
  //       const shape = group.get('children')[0];
  //       // the start position of the edge's path
  //       const startPoint = shape.getPoint(0);
  
  //       // add red circle shape
  //       const circle = group.addShape('circle', {
  //         attrs: {
  //           x: startPoint.x,
  //           y: startPoint.y,
  //           fill: '#1890ff',
  //           r: 3,
  //         },
  //         name: 'circle-shape',
  //       });
  
  //       // animation for the red circle
  //       circle.animate(
  //         (ratio) => {
  //           // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
  //           // get the position on the edge according to the ratio
  //           const tmpPoint = shape.getPoint(ratio);
  //           // returns the modified configurations here, x and y here
  //           return {
  //             x: tmpPoint.x,
  //             y: tmpPoint.y,
  //           };
  //         },
  //         {
  //           repeat: true, // Whether executes the animation repeatly
  //           duration: 3000, // the duration for executing once
  //         },
  //       );
  //     }
  //   },
  //   grow:{
  //     afterDraw(cfg, group) {
  //       if(!cfg.animate || cfg.animate != 'grow') return;
  //       const shape = group.get('children')[0];
  //       const length = shape.getTotalLength();
  //       shape.animate(
  //         (ratio) => {
  //           // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
  //           const startLen = ratio * length;
  //           // Calculate the lineDash
  //           const cfg = {
  //             lineDash: [startLen, length - startLen],
  //           };
  //           return cfg;
  //         },
  //         {
  //           repeat: true, // Whether executes the animation repeatly
  //           duration: 2000, // the duration for executing once
  //         },
  //       );
  //     }
  //   }
  // },
  spread(value, item){
    const group = item.getContainer(); 
    const shape = item.getKeyShape();
    //
    const type = shape.cfg.type;
    const color = shape.attrs.stroke || shape.attrs.fill || '#40a9ff'; 
    const size = getShapeSize(shape)
    //
    const shapes = group.findAll(item => item && item.cfg && item.cfg.name == 'spread-shape')
    if(value){
      if(shapes.length > 0) return;
      const ant = {duration: 3000,easing: 'easeCubic',repeat: true}
      const attr = {x:shape.attrs.x,y:shape.attrs.y,fill:color,opacity:0.5}
      const dSize = changeShapeSize(type,size,10,attr)
      //
      group.addShape(type,{zIndex:-99,attrs:{...attr,...size},name:'spread-shape'}).animate({...attr,...dSize,opacity: 0.1},{...ant,delay:0})
      group.addShape(type,{zIndex:-100,attrs:{...attr,...size},name:'spread-shape'}).animate({...attr,...dSize,opacity: 0.1},{...ant,delay:1000})
      group.addShape(type,{zIndex:-101,attrs:{...attr,...size},name:'spread-shape'}).animate({...attr,...dSize,opacity: 0.1},{...ant,delay:3000})
      group.sort();
    }else{
      //
      shapes.forEach(e => group.removeChild(e));
    }
  },
  edgeRun(value,item){
    const group = item.getContainer(); 
    const shape = group.get('children')[0];
    const startPoint = shape.getPoint(0);
    const attr = {x:startPoint.x,y:startPoint.y,fill:'#1890ff',r:3}
    if(value){
      group.addShape('circle',{name:'edge-run-shape',attrs:attr}).animate((ratio) => {
        const tmpPoint = shape.getPoint(ratio);
        return {
          x: tmpPoint.x,
          y: tmpPoint.y,
        };
      },{repeat:true,duration:3000})
    }else{
      const sps = group.findAll(item => item && item.cfg && item.cfg.name == 'edge-run-shape')
      sps.forEach(e => group.removeChild(e))
    }
    
  },
  edgeDash(value,item){
    const group = item.getContainer(); 
    const shape = group.get('children')[0];
    const style = item.getModel().style
    let index = 0;
    if(value){
      shape.animate(
        () => {
          index++;
          if (index > 9) {
            index = 0;
          }
          const res = {
            lineDash,
            lineDashOffset: -index,
            style
          };
          // returns the modified configurations here, lineDash and lineDashOffset here
          return res;
        },
        {
          repeat: true, // whether executes the animation repeatly
          duration: 3000, // the duration for executing once
        },
      );
    }else{
      shape.stopAnimate();
      shape.attr('lineDash', null);
    }
    
  },
  edgeGrow(value,item){
    const group = item.getContainer(); 
    const shape = group.get('children')[0];
    const length = shape.getTotalLength();
    if(value){
      shape.animate(
        (ratio) => {
          // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
          const startLen = ratio * length;
          // Calculate the lineDash
          const cfg = {
            lineDash: [startLen, length - startLen],
          };
          return cfg;
        },
        {
          repeat: true, // Whether executes the animation repeatly
          duration: 2000, // the duration for executing once
        },
      );
    }else{
      shape.stopAnimate();
    }
  }
}
  
const nodeOptions = {
  // 

  // 绘制节点 link pointer - 与 behavior 联动可定制事件 动画
  afterDraw(cfg, group) {
    const sizes = Array.isArray(cfg.size) ? cfg.size:[cfg.size,cfg.size]
    if(cfg.links){
      const links = cfg.links;
      //
      const map = {top:{y:-sizes[1]/2,direction:'top'},bottom:{y:sizes[1]/2,direction:'bottom'},left:{x:-sizes[0]/2,direction:'left'},right:{x:sizes[0]/2,direction:'right'}}
      //
      const {fill='transparent',stroke='transparent',size=6} = links
      //
      Object.keys(map).forEach(k => {
          if(links[k]){
            const d = getType(links[k]) == 'object' ? {...links[k]} : {}
            group.addShape('circle',{attrs:{x:0,y:0,lineWidth: 1,r:size,fill,stroke,cursor:'crosshair',...map[k],...d},name:'link-point'})
          }
      })
    }
  },
  setState(name, value, item){
    switch(name){
      case 'spread': animation.spread.call(this,value,item);
      break;
      default:{
        const group = item.getContainer(); 
        const ss = item.getStateStyle(name)
        const oris = item.getOriginStyle();
        const k = Object.keys(oris).filter(k => /keyShape$/.test(k))[0]
        const style = value?ss:k?oris[k]:oris
        const shape = item.getKeyShape();
        shape.attr(style)
        //
        elemNames.forEach(k => {
          if(ss && Object.prototype.hasOwnProperty.call(ss,k)){
            const ori = oris[k]
            if(!ori) return;
            const s = ss[k];
            const o = {};
            Object.keys(s).forEach(k => {o[k] = ori[k]});
            const ts = value?s:o;
            const shapes = group.findAll(item => item && item.cfg && item.cfg.name == k)
            shapes.forEach(sp => sp.attr(ts))
          }
        })
      }
        
    }
  }
}
const edgeOptions = {
  setState(name, value, item){
    switch(name){
      case 'edgeRun':animation.edgeRun.call(this,value,item);
      break;
      case 'edgeDash':animation.edgeDash.call(this,value,item);
      break;
      case 'edgeGrow':animation.edgeGrow.call(this,value,item);
      break;
      default:{
        const style = value?item.getStateStyle(name):item.getOriginStyle()['edge-shape'] ? item.getOriginStyle()['edge-shape'] : item.getOriginStyle();
        const shape = item.getKeyShape();
        shape.attr(style)
      }
    }
  }
};
//
const nodeTypes = ['circle','rect','ellipse','diamond','image','modelRect']
const edgeTypes = ['line','polyline','arc','quadratic','cubic','cubic-vertical','cubic-horizontal','loop']
export const regiestItems = (G6,exts) => {
  // 注册 nodes 
  nodeTypes.forEach(k => {
    G6.registerNode(`mike-${k}`,{...nodeOptions},k)
  })
  // 注册 edges
  edgeTypes.forEach(k => {
    G6.registerEdge(`mike-${k}`,{...edgeOptions},k)
  })

  if(exts && typeof exts === 'object' && (exts.nodes || exts.edges)){
    const {nodes=[],edges=[]} = exts;
    nodes.forEach(({name,options,type}) => {
      G6.regiestNode(name,options,type)
    })
    edges.forEach(({name,options,type}) => {
      G6.regiestEdge(name,options,type)
    })
  }
}

//

export {nodes,itemsForm,itemsFormData};
