// 工具方法集合
export const getType = (o) => {
  const t = Object.prototype.toString.call(o);
  return /^\[object (.*)\]$/.exec(t)[1].toLowerCase();
}
// 获取 图形 size
const shapeSize = {
  circle:['r'],
  rect:['width','height','radius'],
  image:['width','height'],
  ellipse:['rx','ry'],
  polygon:['points'],
  path:['path']
}
export const getShapeSize = (shape) => {
  const type = shape.cfg.type;
  const attrs = shape.attrs;
  //
  const res = {}
  if(shapeSize[type]){
    shapeSize[type].forEach(k => res[k] = attrs[k])
  }
  return res;
}

// 
const sizeKeys = ['r','rx','ry','width','height']
export const changeShapeSize = (type,size,d = 0,attr) => {
  let sum = 0;
  const res = {}
  let ct = 0;
  sizeKeys.forEach(k => {
    if(size[k] != undefined){
      ct++;
      sum += size[k]
      res[k] = size[k]
    }
  })
  const p = (sum + d * ct) / sum;
  //
  if(type == 'path'){
    res.path = size.path.map(p => {
      const r = [...p]
      if(r.length  == 3){
        r[1] = r[1] > 0 ? r[1] + d: r[1] < 0 ? r[1] = r[1] - d:r[1];
        r[2] = r[2] > 0 ? r[2] + d: r[2] < 0 ? r[2] = r[2] - d:r[2];
      }
      return r;
    })
  }
  else if(type == 'polygon'){
    res.points = size.points.map(pos => [pos[0] + d,pos[1] + d]);
    // console.log(res)
  }else{
    Object.keys(res).forEach(k => {
      
      res[k] *= p
      if(k == 'width'){
        res.x = attr.x - (res.width - size.width) / 2
      }
      if(k == 'height'){
        res.y = attr.y - (res.height - size.height) / 2
      }
    })
  }
  return res;
}



// 通过遍历边和节点的集合，梳理出节点流程 ID 邻接表数据 原理：初始节点不会成为 target 只针对有边集合的流程图
// [id1,{yes:[id2,{0:id4,1:id5,2:[id6,id7]}],no:[id3]},id999]
export const createAdjacencyLists = (data,form = {}) => {
  const {nodes,edges,combos} = data;
  const n = nodes.map(item => item.id)
  const e = edges.map(item => item.id)
  const s = edges.map(item => item.source)
  const t = edges.map(item => item.target)
  const st = new Set(t)
  // 初始节点  s,t 差集
  const f = n.filter(id => !st.has(id))
  const len = f.length;
  // 构建邻接表
  const table = [];
  // 默认如果有初始节点，从初始节点按照流程顺序梳理
  if(len > 0){
    for(let i = 0;i < f.length; i++){
      const id = f[i];
      const fd = form[id] || {}
      const index = table.length;
      const nins = n.indexOf(id)
      const nd = {id,index,nodeIndex:nins,form:fd}
      const nt = edges.filter(item => item.source === id).map(({id,target}) => ({id,target,form:form[id] || {}}));
      if(nt.length > 0){
        nd.next = nt.map(({target:id,id:eid},ins) => {
          f.push(id);
          const edgeIndex = e.indexOf(eid)
          return {id,index:f.length - 1,edgeIndex,edgeId:eid}
        })
      }
      table.push(nd)
    }
  }else{
    n.forEach((id,nodeIndex) => {
      const fd = form[id] || {}
      const index = table.length;
      const nd = {id,index,nodeIndex,form:fd};
      const nt = edges.filter(item => item.source === id).map(({id,target}) => ({id,target,form:form[id] || {}}));
      if(nt.length > 0){
        nd.next = nt.map(({target:id,id:eid},ins) => {
          const edgeIndex = e.indexOf(eid)
          return {id,index:n.indexOf(id),edgeIndex,edgeId:eid}
        })
      }
      table.push(nd)
    })
  }
  // console.log(table)
  return {table}
}

// 脚本执行 data : 邻接表数据
// config  = {$ajax,$api,$config}
// export const jsExec = (data,config = {}) => {
//   const d = {$ajax:() => {},$api = {}}
//   const $common = {...d,...config}

// }