import G6 from "@antv/g6";

// graph 全局配置
const graphConfig = {
  // fitView: true,
  cursor: "pointer",
  enabledStack: true,
  animate: true,
  modes: {
    auto:["drag-node","drag-canvas","zoom-canvas","graph-common"],
    add: ["drag-canvas", "zoom-canvas", "add-model"],
    view: ["drag-canvas", "zoom-canvas"],
    // delete: ["drag-canvas", "zoom-canvas", "graph-common", "delete-model"],
  },
  // 静态状态 
  nodeStateStyles:{
    hover:{
      lineWidth:3,
      lineDash:[5,2],
      // 'link-point':{
      //   stroke: 'skyblue',
      // },
      // 'text-shape': {
      //   stroke: 'red',
      // },
    },
    select:{
      lineWidth:3,
      lineDash:[5,2],
      stroke:'red'
    }
  },
  edgeStateStyles:{
    hover:{
      lineWidth:4,
    },
    select:{
      lineWidth:4,
      stroke:'red'
    }
  },
  comboStateStyles:{},
};

// graph 监听
const graphOn = {
}


// 菜单栏 按钮配置
const menus = {
  model: [],
  tools:[
    {icon:'el-icon-refresh-left',name:'undo',tooltip:{content:'撤销',placement:'top'}},
    {icon:'el-icon-refresh-right',name:'redo',tooltip:{content:'恢复',placement:'top'}},
    {icon:'el-icon-zoom-in',name:'zoom-in',tooltip:{content:'放大',placement:'top'}},
    {icon:'el-icon-zoom-out',name:'zoom-out',tooltip:{content:'缩小',placement:'top'}},
  ],
  graph: [
    {icon:'el-icon-check',name:'submit',tooltip:{content:'提交保存',placement:'top'}},
  ],
  setting:[
    {icon:'el-icon-setting',name:'setting',tooltip:{content:'设置',placement:'top'}},
  ]
}

// 图形数据中可成为表单数据的 key 集合 这些值会写入到元素表单数据中，并且也会同步更新到 图形中，id,name 不可更改。。。是否可更改可以用 false|true 来确定
const modelDataKeys = ['id','name']
// 默认元素名称
const defaultElemNames = ['start','end','ajax','edge','judge','do','schedule']
//
const defaultElemGraphForm = {
  label: "图形属性",
  name: "graph",
  column: [
    {
      tag: "form-group",
      title: "初始属性配置",
      style: { height: "400px" },
      cls: [
        { label:'节点 ID',prop:'id',disabled:true },
        { label:'节点名称',prop:'name',disabled:true },
        { label: "唯一 key", prop: "key" },
        { label: "节点标签", prop: "label"  },
      ]
    }
  ]
}
// 默认元素的表单项
const defaultElemForm = [
  {
    label:'脚本属性',
    name:'script',
    column:[
      {
        tag: "form-group",
        title: "脚本编辑器",
        style: { height: "400px" },
        cls: [
          {
            tag: "els-monaco-editor",
            title: "脚本",
            prop: "script"
          },
        ],
      }
    ]
  },
  {
    label: "图形属性",
    name: "graph",
    column: [
      {
        tag: "form-group",
        title: "初始属性配置",
        style: { height: "400px" },
        cls: [
          { label:'节点 ID',prop:'id',disabled:true },
          { label:'节点名称',prop:'name',disabled:true },
          { label: "唯一 key", prop: "key" },
          { label: "节点标签", prop: "label"  },
        ]
      }
    ],
  }
]
// 默认保存的节点属性列表
const defaultSaveNodeAttrs = ['name','type','x','y','id','key','label']
// 显示节点面板的模式
const showNodePanel = ["add"];

// 显示属性配置面板的模式
const showPropertyPanel = ["line"];

// 鼠标指针样式
const cursor = [
  {
    tag: "form-group",
    title: "选择鼠标指针样式",
    cls: [
      {
        tag: "el-select",
        label: "鼠标指针",
        prop: "cursor",
        cls: [
          { value: "default" },
          { value: "pointer" },
          { value: "help" },
          { value: "wait" },
          { value: "text" },
          { value: "move" },
          { value: "crosshair" },
          { value: "s-resize" },
          { value: "w-resize" },
          { value: "se-resize" },
          { value: "ne-resize" },
        ],
      },
    ],
  },
];

// 公共样式层
const style = [
  { tag: "el-color-picker", label: "填充颜色", prop: "fill" },
  { tag: "el-color-picker", label: "描边颜色", prop: "stroke" },
  { tag: "el-input-number", label: "描边宽度", prop: "lineWidth", min: 0 },
  { tag: "el-input-number", label: "描边虚线", prop: "lineDash" },
  { tag: "el-color-picker", label: "阴影颜色", prop: "shadowColor" },
  { tag: "el-input-number", label: "模糊半径", prop: "shadowBlur" },
  { tag: "el-input-number", label: "水平偏移", prop: "shadowOffsetX" },
  { tag: "el-input-number", label: "垂直偏移", prop: "shadowOffsetY" },
  {
    tag: "el-slider",
    label: "不透明度",
    prop: "opacity",
    min: 0,
    max: 1,
    step: 0.01,
    value: 1,
  },
  {
    tag: "el-slider",
    label: "填充不透明度",
    prop: "fillOpacity",
    min: 0,
    max: 1,
    step: 0.01,
    value: 1,
  },
  {
    tag: "el-slider",
    label: "描边不透明度",
    prop: "strokeOpacity",
    min: 0,
    max: 1,
    step: 0.01,
  },
];

// 边的样式
const edgeStyle = [
  {
    tag: "form-group",
    title: "边的通用属性",
    cls: [
      { tag: "el-color-picker", label: "颜色", prop: "stroke" },
      { tag: "el-input-number", label: "宽度", prop: "lineWidth", min: 1 },
      {
        tag: "el-slider",
        label: "透明度",
        prop: "strokeOpacity",
        min: 0,
        max: 1,
        step: 0.1,
        value: 1,
      },
      {
        tag: "el-input-number",
        label: "悬浮宽度",
        prop: "lineAppendWidth",
        min: 1,
      },
    ],
  },
  {
    tag: "form-group",
    title: "边的阴影属性",
    cls: [
      { tag: "el-color-picker", label: "颜色", prop: "shadowColor" },
      { tag: "el-input-number", label: "模糊半径", prop: "shadowBlur" },
      { tag: "el-input-number", label: "水平偏移", prop: "shadowOffsetX" },
      { tag: "el-input-number", label: "垂直偏移", prop: "shadowOffsetY" },
    ],
  },
  {
    tag: "form-group",
    title: "虚线边属性设置",
    prop: "lineDash",
    cls: [
      { tag: "el-input-number", label: "实线长度", prop: "0" },
      { tag: "el-input-number", label: "间隙长度", prop: "1" },
    ],
  },
];

// node label style
const nodeLabelStyle = [
  {
    tag: "form-group",
    title: "文本位置属性",
    cls: [
      {
        tag: "el-select",
        label: "位置",
        prop: "position",
        disabled: true,
        cls: [
          { label: "center", value: "center" },
          { label: "top", value: "top" },
          { label: "left", value: "left" },
          { label: "right", value: "right" },
          { label: "bottom", value: "bottom" },
        ],
      },
      { tag: "el-input-number", label: "文本偏移", prop: "offset" },
    ],
  },
  {
    tag: "form-group",
    title: "文本样式属性",
    prop: "style",
    cls: [...style],
  },
  ...cursor,
];

// edge label style
const edgeLabelStyle = [
  {
    tag: "form-group",
    title: "文本位置属性",
    cls: [
      { tag: "el-input-number", label: "水平偏移", prop: "refX" },
      { tag: "el-input-number", label: "垂直偏移", prop: "refY" },
      {
        tag: "el-select",
        label: "位置",
        prop: "position",
        cls: [
          { label: "start", value: "start" },
          { label: "middle", value: "middle" },
          { label: "end", value: "end" },
        ],
      },
      { tag: "el-switch", label: "自动旋转", prop: "autoRotate" },
    ],
  },
  {
    tag: "form-group",
    title: "文本样式属性",
    prop: "style",
    cls: [...style],
  },
  ...cursor,
];

// 边 的箭头样式
const arrowStyle = [
  { label: "triangle", value: G6.Arrow.triangle(10, 20, 25) },
  { label: "vee", value: G6.Arrow.vee(10, 20, 25) },
  { label: "circle", value: G6.Arrow.circle(10, 25) },
  { label: "diamond", value: G6.Arrow.diamond(10, 20, 25) },
  { label: "rect", value: G6.Arrow.rect(10, 20, 25) },
  { label: "triangleRect", value: G6.Arrow.triangleRect(15, 15, 15, 3, 5, 25) },
];
const edgeArrow = [
  {
    tag: "form-group",
    title: "边的箭头属性",
    cls: [
      // { tag: "form-group", title: "起点箭头", prop:'startArrow',cls: [
      //   { tag: "el-select", prop: "path", label: "样式选择", cls: [
      //     ...arrowStyle
      //   ]},
      //   {tag:'el-input-number',label:'D',prop:'d',value:25},
      // ]},
      // { tag: "form-group", title: "终点箭头", prop:'endArrow',cls: [

      //   { tag: "el-select", prop: "path", label: "样式选择", cls: [
      //     ...arrowStyle
      //   ]},
      //   {tag:'el-input-number',label:'D',prop:'d',value:25},
      // ] }
      { tag: "el-switch", label: "起点箭头", prop: "startArrow" },
      { tag: "el-switch", label: "终点箭头", prop: "endArrow" },
    ],
  },
];

// 状态样式
const stateStyles = {
  hover: {
    // lineWidth: 2,
    shadowBlur: 10,
    shadowColor: "#0DC0F7",
    stroke: "#1BE4DD",
  },
  select: {
    // lineWidth: 2,
    shadowBlur: 10,
    shadowColor: "#4290FE",
  },
  undo: {
    // lineWidth: 1,
    shadowBlur: 10,
    stroke: "#F4B223",
    shadowColor: "#F5C126",
  },
  success: {
    // lineWidth: 1,
    shadowBlur: 10,
    stroke: "#15F95E",
    shadowColor: "#23F75F",
  },
  fail: {
    // lineWidth: 1,
    shadowBlur: 10,
    stroke: "#F82112",
    shadowColor: "#F71515",
  },
};
// 字典，节点类型
const nodeTypes = [
  { label: "圆形", value: "circle" },
  { label: "矩形", value: "rect" },
  { label: "椭圆", value: "ellipse" },
  { label: "菱形", value: "diamond" },
  { label: "三角形", value: "triangle" },
  { label: "星形", value: "star" },
  { label: "图片节点", value: "image" },
  { label: "卡片节点", value: "modelRect" },
];
// 字典，边的类型
const edgeTypes = [
  { label: "直线", value: "mike-line" },
  { label: "折线", value: "mike-polyline" },
  { label: "圆弧", value: "mike-arc" },
  { label: "曲线", value: "mike-quadratic" },
  { label: "贝塞尔曲线", value: "mike-cubic" },
  { label: "垂直贝塞尔曲线", value: "mike-cubic-vertical" },
  { label: "水平贝塞尔曲线", value: "mike-cubic-horizontal" },
  { label: "自环", value: "mike-loop" },
];
// 基础元素属性 form 表单 用于支持多标签 form 可显示边、节点、的属性
// const baseItemForm = [
//   // 编辑模式下的基础属性
//   {
//     tag: "基础属性",
//     column: [
//       { label: "ID", prop: "id", disabled: true },
//       {
//         tag: "el-select",
//         label: "类型",
//         prop: "type",
//         disabled: true,
//         cls: [...nodeTypes, ...edgeTypes],
//       },
//       { tag: "el-input-number", label: "X 坐标", prop: "x", disabled: true },
//       { tag: "el-input-number", label: "Y 坐标", prop: "y", disabled: true },
//       { tag: "el-slider", label: "尺寸", prop: "size", min: 0, max: 100 },
//       { label: "文本", prop: "label" }
//     ],
//   },
//   // 编辑模式下扩展表单项 ${}
//   {
//     tag:'输入变量',
//     column:[

//     ]
//   },

// ];
//
const nodeForm = [
  {
    tag: "form-group",
    title: "位置属性",
    cls: [
      { tag: "el-input-number", label: "X 坐标", prop: "x" },
      { tag: "el-input-number", label: "Y 坐标", prop: "y" },
    ],
  },

  // { tag: "el-slider", label: "尺寸", prop: "size", min: 0, max: 100 },
];
// 边表单
const edgeForm = [
  // { tag: "el-select", prop: "type", label: "线型选择", cls: [...edgeTypes] , disabled: true },
  {
    tag: "el-input-number",
    prop: "sourceAnchor",
    label: "起始锚点索引",
    min: 0,
    max: 3,
  },
  {
    tag: "el-input-number",
    prop: "targetAnchor",
    label: "结束锚点索引",
    min: 0,
    max: 3,
  },
];
// 扩展节点表单
const extendNodeForm = [{ label: "名称", prop: "name" }];
// 扩展边表单
const extendEdgeForm = [{ label: "名称", prop: "name" }];

const editCommonForm = [
  {
    tag: "form-group",
    title: "基础属性",
    cls: [
      { label: "ID", prop: "id", disabled: true },
      {
        tag: "el-select",
        label: "类型",
        prop: "type",
        disabled: true,
        cls: [...nodeTypes, ...edgeTypes],
      },
      { label: "文本", prop: "label" },
    ],
  },
];
// 输入变量
const inputForm = [
  {
    tag: "form-group",
    title: "输入变量",
    cls: [
      {
        tag: "item-table",
        prop: "inputs",
        column: [
          { label: "变量名", prop: "name", form: {} },
          { label: "变量值", prop: "value", form: {} },
        ],
      },
    ],
  },
];
//  输出变量
const outputForm = [
  {
    tag: "form-group",
    title: "输出变量",
    cls: [
      {
        tag: "item-table",
        prop: "outputs",
        column: [
          { label: "变量名", prop: "name", form: {} },
          { label: "变量值", prop: "value", form: {} },
        ],
      },
    ],
  },
];

// 脚本
const scriptForm = [
  {
    tag: "form-group",
    title: "自定义脚本",
    style: { height: "400px" },
    cls: [
      {
        tag: "els-monaco-editor",
        prop: "script",
        title: "脚本",
        langsDisabled: false,
      },
    ],
  },
];

// 编辑状态下 节点 表单
const editNodeForm = [
  {
    label: "图形属性",
    name: "base",
    alias: "editNodeBase",
    column: [...editCommonForm, ...nodeForm],
  },
  {
    label: "扩展属性",
    name: "extends",
    column: [],
  },
  // {
  //   label: "输入变量",
  //   name: "editNodeInput",
  //   column: [...inputForm],
  // },
  {
    label: "脚本",
    name: "editNodeScript",
    column: [...scriptForm],
  },
  // {
  //   label: "输出变量",
  //   name: "editNodeOutput",
  //   column: [...outputForm],
  // },
];

// 编辑状态下 边的表单
const editEdgeForm = [
  {
    label: "图形属性",
    name: "base",
    alias: "editEdgeBase",
    column: [...editCommonForm, ...edgeForm],
  },
  // {
  //   label: "输入变量",
  //   name: "editEdgeInput",
  //   column: [...inputForm],
  // },
  {
    label: "脚本",
    name: "editEdgeScript",
    column: [...scriptForm],
  },
  // {
  //   label: "输出变量",
  //   name: "editEdgeOutput",
  //   column: [...outputForm],
  // },
];

// 连线模式下 边的表单
const lineEdgeForm = [
  {
    label: "边属性配置",
    name: "edgeConfig",
    column: [
      {
        tag: "form-group",
        title: "选择边的类型",
        cls: [
          {
            tag: "el-select",
            prop: "type",
            label: "线型选择",
            cls: [...edgeTypes],
          },
        ],
      },
      {
        tag: "form-group",
        title: "文本样式属性",
        prop: "labelCfg",
        cls: [...edgeLabelStyle],
      },
      {
        tag: "form-group",
        title: "图形样式属性",
        prop: "style",
        cls: [...edgeArrow, ...edgeStyle, ...cursor],
      }
    ],
  }
];


// 图配置项  边的类型和数据表单 节点数据表单 // 
const graphConfigForm = [
  ...lineEdgeForm
]
// 图配置项默认数据
const defautGraphConfigData = {
  edgeConfig:{
    type:'mike-cubic',
    style:{
      stroke:'#0AB3F1',
      lineWidth:2,
      endArrow:true,
    },
    labelCfg:{
      autoRotate:true,
      style:{
        fill:'#ED8A13',
        stroke:'white',
        lineWidth:5,
      }
    }
  }
}

// 导出
export {
  graphConfig,
  graphOn,
  style,
  menus,
  modelDataKeys,
  defaultElemNames,
  defaultElemForm,
  defaultElemGraphForm,
  stateStyles,
  nodeTypes,
  edgeTypes,
  editNodeForm,
  editEdgeForm,
  graphConfigForm,
  defaultSaveNodeAttrs,
  defautGraphConfigData
};
